import { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import EligibleAddress from './EligibleAddress.js';
import TermsAndConditions from './TermsAndConditions.js';
import sleeper from '../utils/sleep-promise.js';
import {
  toggleErrorAlert,
  toggleErrorAlreadyEnrolledAlert
} from './EnrollmentHelper'
import logger, {getSpaRequestId} from '../utils/logger.js';
import setAnalyticsData from '../utils/adobe.js';
import { refreshSession, getOktaToken, getSSOToken, refreshOktaToken, removeAuthCookies } from '../utils/session-management.js';
import ChatBot from './Chatbot.js';

const spaRequestId = getSpaRequestId()

export default function EnrollmentForm({ data }) {
  const cmpid = useRef("");

  const navigate = useNavigate()
  const location = useLocation()
  const query = new URLSearchParams(location.search)

  useEffect(() => {
    if(!data.eligibleResidences[0].addressLine1 || !data.eligibleResidences[0].city || !data.eligibleResidences[0].state || !data.eligibleResidences[0].zip || !data.firstName || !data.lastName) {
      if(document.getElementById('errorMissingInformationAlert').classList.contains("d-none")) {
        document.getElementById('errorMissingInformationAlert').classList.toggle('d-none')
        window.scrollTo(0, 0);
      }
    }

    const extcmpid = query.get('cmpid') ? "cmpid=" + query.get('cmpid') : "";
    const intcmpid = query.get('intcmpid') ? "intcmpid=" + query.get('intcmpid') : "";

    if (extcmpid !== "" && intcmpid !== "") {
      cmpid.current = "?" + extcmpid + "&" + intcmpid;
    } else if(extcmpid !== "") {
      cmpid.current = "?" + extcmpid;
    } else if(intcmpid !== "") {
      cmpid.current = "?" + intcmpid;
    }
  }, []);

  useLayoutEffect(()=>{
    const element = document.getElementById('shippingAddressContainer')
    window.oneX.addElement(element);

    return () => {
      window.oneX.removeElement(element);
    }
  },[]);

  setAnalyticsData({
    screenId: 'ting-enrollment',
    screenChannel: 'ting',
    clientID: data.customerId
  })

  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);

  const enrollUser = async (event, tries = 1) => {
    try {
      event.preventDefault()

      document.getElementById('loadSpinner').style.display = "block";
      document.getElementById('submitButton').disabled = true;

      let selectedAddressIndex = document.querySelector('.eligible-address-item input[type=radio]:checked').value;

      let isValidSession = await refreshSession()
      if (!isValidSession) {
        removeAuthCookies()
        const proofingURL = process.env.REACT_APP_AUTH_URL + "?goto=" + encodeURIComponent(process.env.REACT_APP_DOMAIN + cmpid.current)
        window.location.href = proofingURL
      }

      const formElements = event.target.firstName !== undefined ? event.target : event.target.elements

      const requestBody = JSON.stringify({
        first_name: formElements.firstName.value,
        last_name: formElements.lastName.value,
        email: formElements.email.value,
        state_farm_customer_id: data.customerId,
        enrollment_date: new Date().toISOString(),
        phone: formElements.phone.value.replace(/[^0-9]/g, '').toString(),
        is_mobile_phone: formElements.mobileNumber.checked === true,
        address_1: data.eligibleResidences[selectedAddressIndex].addressLine1,
        address_2: data.eligibleResidences[selectedAddressIndex].addressLine2,
        city: data.eligibleResidences[selectedAddressIndex].city,
        state: data.eligibleResidences[selectedAddressIndex].state,
        zip: data.eligibleResidences[selectedAddressIndex].zip,
        country: 'USA',
        shipping_address_1: formElements.sameAsEligibleAddress.checked  === true ? data.eligibleResidences[selectedAddressIndex].addressLine1 : formElements['shipping-address_line1-id'].value,
        shipping_address_2: formElements.sameAsEligibleAddress.checked  === true ? data.eligibleResidences[selectedAddressIndex].addressLine2 : formElements['shipping-address_line2-id'].value,
        shipping_city: formElements.sameAsEligibleAddress.checked  === true ? data.eligibleResidences[selectedAddressIndex].city : formElements['shipping-city-id'].value,
        shipping_state: formElements.sameAsEligibleAddress.checked  === true ? data.eligibleResidences[selectedAddressIndex].state : formElements['shipping-state-id'].value,
        shipping_zip: formElements.sameAsEligibleAddress.checked  === true ? data.eligibleResidences[selectedAddressIndex].zip : formElements['shipping-zip-id'].value,
        shipping_country: 'USA',
        consent_program_terms: formElements.termsAndConditionsCheckbox.checked  === true,
        consent_sms: formElements.textMessageConsent.checked  === true,
        cfip_acknowledged: true,
        agreement_id: parseInt(data.eligibleResidences[selectedAddressIndex].agreementIndexId, 10),
        pa_version: process.env.REACT_APP_TING_PARTICIPANT_AGREEMENT_VERSION
      }, (key, value) => {
        if (value !== null && value !== "") return value
      });

      logger(`Enrollment initiated for customer: ${data.customerId}`, requestBody)

      let authToken = ""
      // Prioritize Okta, but use SSO as fallback
      if (await refreshOktaToken()) { // Refresh the token right before sending it downstream
        let oktaToken = getOktaToken()
        authToken = oktaToken !== undefined && oktaToken.length > 0 ? `Bearer ${oktaToken}` : ""
      } else { // Default to SSO if Okta is "not good/available", at least until SSO is sunset
        authToken = getSSOToken()
      }

      const res = await fetch(process.env.REACT_APP_TING_API_URL + '/enrollment', {
        body: requestBody,
        headers: {
          'Content-Type': 'application/json',
          'authorizationToken': authToken,
          'x-sf-ting-spaRequestId': spaRequestId
        },
        method: 'POST'
      })

      // Capture agreementId and add it to the `data` object
      // so that it can be passed down to `success` for Adobe Analytics
      const selectedAgreementId = parseInt(data.eligibleResidences[selectedAddressIndex].agreementIndexId, 10)
      data.selectedAgreementId = selectedAgreementId

      const result = await res;
      const resultBody = await res.json()

      if (result.status === 201 || result.status === 200) {
        logger(`Enrollment successful for customer: ${data.customerId}`, resultBody)
        navigate('success', { state: data })
      } else if (result.status === 401 || result.status === 403) {
        // Redirect to proofing
        logger(`Enrollment failed for customer: ${data.customerId}. Redirect to proofing`)
        const proofingURL = process.env.REACT_APP_AUTH_URL + "?goto=" + encodeURIComponent(process.env.REACT_APP_DOMAIN)
        window.location.href = proofingURL
      } else if (result.status === 400 && resultBody != null && resultBody.message != null && resultBody.message.includes("Duplicate Agreement id")) {
        logger(`Enrollment failed for customer: ${data.customerId} due to "Duplicate Agreement id"`)
        navigate('success', { state: data })
      } else if ((result.status === 400 || result.status === 500) && resultBody != null && resultBody.message != null && resultBody.message.includes("duplicate key value violates unique constraint")) {
        logger(`Enrollment failed for customer: ${data.customerId} due to "duplicate key value violates unique constraint"`)
        toggleErrorAlreadyEnrolledAlert()
        document.getElementById('loadSpinner').style.display = "none";
        document.getElementById('submitButton').disabled = false;
      } else if (result.status === 400 || result.status === 500 || result.status === 502 || result.status === 504) {
        if (tries < process.env.REACT_APP_MAX_ENROLLMENT_TRIES) {
          // Wait time to try again gets incrementally longer
          await sleeper(tries * process.env.REACT_APP_ENROLLMENT_RETRY_WAIT_INTERVAL);
          // Since the UI does not know if the server processed the enrollment prior to receiving the 5xx
          // a retry is attempted. If the prior enrollment was successful a 400 or 500 w/ "duplicate key value" message
          // would have been returned so this condition would have never been reached.
          logger(`Enrollment retry #${tries}`)
          enrollUser(event, tries + 1)
        } else {
          logger('Enrollment error redirecting to /error/technical due to status ', result.status)
          navigate('error/technical?err=Enrollment&cd=' + result.status)
        }
      } else {
        toggleErrorAlert()
        document.getElementById('errorDefaultAlert').href= "mailto:State%20Farm%20Ting%20Support%20%3chome.fire-tingtelematics.664i00@statefarm.com%3e?subject=SF%20Ting%20Enrollment%20Form%20Error-ErrorCode%20" + result.status;
        document.getElementById('loadSpinner').style.display = "none";
        document.getElementById('submitButton').disabled = false;
      }
    } catch (error) {
      logger('Enrollment error', error)
      navigate('error/technical')
    }
  }

  function toggleShippingAddress() {
    if(document.getElementById('sameAsEligibleAddress').checked) {
      document.getElementById('shippingAddressContainer').style.visibility = 'hidden'
    } else {
      document.getElementById('shippingAddressContainer').style.visibility = 'visible'
    }
  }

  // Disable the Submit button if any of the form fields are invalid/missing
  // or the user has not accepted the Terms and Conditions
  function checkForEmptyFields() {
    if (document.getElementById('firstName').value.trim().length > 0 &&
        document.getElementById('lastName').value.trim().length > 0 &&
        document.getElementById('email').value.trim().length > 0 &&
        document.getElementById('email').value.trim().match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) &&
        document.getElementById('phone').value.trim().replace(/[^0-9]/g, '').toString().length === 10 &&
        (document.getElementById('sameAsEligibleAddress').checked ||
          (document.getElementById('shipping-address_line1-id')?.value &&
            document.getElementById('shipping-state-id')?.value &&
            document.getElementById('shipping-city-id')?.value &&
            document.getElementById('shipping-zip-id')?.value
          )
        ) && document.getElementById('termsAndConditionsCheckbox').checked)
    {
      document.getElementById('submitButton').disabled = false;
    } else {
      document.getElementById('submitButton').disabled = true;
    }
  }

  function defaultValueForPhone() {
    let mobilePhoneNumber = "";
    let homePhoneNumber = "";

    if (data.telephoneNumbers != null && data.telephoneNumbers.length > 0) {
      data.telephoneNumbers.forEach(telephone => {
        if (telephone.usage === "Home") {
          homePhoneNumber = telephone.number
        } else if (telephone.usage === "Mobile") {
          mobilePhoneNumber = telephone.number
        }
      })

      if (mobilePhoneNumber !== "") {
        return mobilePhoneNumber
      } else if(homePhoneNumber !== "") {
        return homePhoneNumber
      } else {
        return ""
      }
    }
  }

  function defaultCheckedForMobilePhoneNumber() {
    let mobilePhoneNumber = "";

    if (data.telephoneNumbers != null && data.telephoneNumbers.length > 0) {
      data.telephoneNumbers.forEach(telephone => {
        if (telephone.usage === "Mobile") {
          mobilePhoneNumber = telephone.number
        }
      })

      if (mobilePhoneNumber !== "") {
        return true
      } else {
        return false
      }
    }
  }

  function defaultCheckedForHomeOtherPhoneNumber() {
    let mobilePhoneNumber = "";
    let homePhoneNumber = "";

    if (data.telephoneNumbers != null && data.telephoneNumbers.length > 0) {
      data.telephoneNumbers.forEach(telephone => {
        if (telephone.usage === "Home") {
          homePhoneNumber = telephone.number
        } else if (telephone.usage === "Mobile") {
          mobilePhoneNumber = telephone.number
        }
      })

      if (mobilePhoneNumber === "" && homePhoneNumber !== "") {
        return true
      } else {
        return false
      }
    }
  }

  function disableSMS() {
    document.getElementById('textMessageConsent').disabled = true;
  }

  function enableSMS() {
    document.getElementById('textMessageConsent').disabled = false;
  }

  function clickConsentToSMS() {
  }

  return (
    <div>
      <div id="errorAlert" className="alert alert-danger d-none" role="alert">
        We apologize but something went wrong. Please contact the <a id="errorDefaultAlert" href="mailto:State%20Farm%20Ting%20Support%20%3chome.fire-tingtelematics.664i00@statefarm.com%3e?subject=SF%20Ting%20Enrollment%20Form%20Error" className="alert-link">State Farm Ting Support Team</a> for assistance.
      </div>

      <div id="errorAlreadyEnrolledAlert" className="alert alert-danger d-none" role="alert">
      <h1>Ting Enrollment </h1>
        <p>Thank you for your interest in the Ting Program!  This address has already been enrolled in the program. It is possible that another person named on the policy completed the enrollment.</p>
        <p>If you feel you are receiving this message in error or have additional questions about enrollment, please email your name and property address to the  <a id="errorDuplicateAlert" href="mailto:State%20Farm%20Ting%20Support%20%3chome.fire-tingtelematics.664i00@statefarm.com%3e?subject=SF%20Ting%20Enrollment%20Duplicate" className="alert-link">State Farm Ting Support Team</a></p>
      </div>

      <div id="errorMissingInformationAlert" className="alert alert-danger d-none" role="alert">
        We apologize that you are receiving this error, but it appears your data populated incorrectly. Please reach out to <a id="errorMissingInfoAlert" href="mailto:State%20Farm%20Ting%20Support%20%3chome.fire-tingtelematics.664i00@statefarm.com%3e?subject=SF%20Ting%20Enrollment%20Missing%20Account%Information" className="alert-link">State Farm Ting Support Team</a> to have this issue reviewed.
      </div>

    <form id="enrollmentForm" onSubmit={enrollUser}>
      <div className="-oneX-row">
        <div className="-oneX-col-12 -oneX-col-md-10 -oneX-offset-md-1 -oneX-col-lg-8 -oneX-offset-lg-2 pb-3">
            <p role="heading" aria-level="3" className="-oneX-heading--h3">Contact Information</p>
        </div>

        <div className="-oneX-col-12 -oneX-col-md-10 -oneX-offset-md-1 -oneX-col-lg-8 -oneX-offset-lg-2 pb-3">
          <div className="-oneX">
            <label htmlFor="firstName" className="-oneX-textfield__floatingLabel -oneX--floatUp">First Name</label>
            <input id="firstName" value={data.firstName} name="firstName" type="text" autoComplete="firstName" className="-oneX-textfield--floating-input" required readOnly/>
          </div>
        </div>

        <div className="-oneX-col-12 -oneX-col-md-10 -oneX-offset-md-1 -oneX-col-lg-8 -oneX-offset-lg-2 pb-3">
          <div className="-oneX">
            <label htmlFor="lastName" className="-oneX-textfield__floatingLabel -oneX--floatUp">Last Name</label>
            <input id="lastName" value={data.lastName} name="lastName" type="text" autoComplete="lastName" className="-oneX-textfield--floating-input" required readOnly/>
          </div>
        </div>
      </div>

      {/*
      * For the below label/input fields, refer to https://statefarm.frontify.com/document/298#/actions-and-inputs/text-fields/code
      *  > When a consuming application changes the content/value of a text field (conversational or floating label) programmatically
      *    the application must trigger the change event. This will allow a label, when floating, to be adjusted correctly.
      *    It also updates the placeholder characters when using a formatter.
      *
      * We will need to modify the logic to handle dynamically invoking oneX when the input value changes. For now, we hardcode
      * `oneX--floatUp` to best mirror the behavior in Labs and avoid a non-floating label overlaying the text in the input.
      */}
      <div className="-oneX-row">
        <div className="-oneX-col-12 -oneX-col-md-10 -oneX-offset-md-1 -oneX-col-lg-8 -oneX-offset-lg-2 pb-3">
          <div className="-oneX">
            <label htmlFor="email" className="-oneX-textfield__floatingLabel -oneX--floatUp">Email</label>
            <input id="email" defaultValue={data.email} name="email" type="email" autoComplete="email" className="-oneX-textfield--floating-input" required onInput={checkForEmptyFields.bind(this)}/>
          </div>
        </div>

        <div className="-oneX-col-12 -oneX-col-md-10 -oneX-offset-md-1 -oneX-col-lg-8 -oneX-offset-lg-2 pb-3">
          <div className="-oneX">
            <label htmlFor="phone" className="-oneX-textfield__floatingLabel -oneX--floatUp">Phone</label>
            <input id="phone" defaultValue={defaultValueForPhone()} name="phone" type="phone" autoComplete="phone" className="-oneX-textfield--floating-input" data-formatter="phone" required onInput={checkForEmptyFields.bind(this)}/>
          </div>
        </div>
      </div>

      <div className="-oneX-row py-3">
        <div className="-oneX-col-12 -oneX-col-md-3 -oneX-offset-md-2">
          <fieldset className="-oneX -oneX-fieldset">
            <legend className="-oneX-legend">This number is:</legend>
            <div className="-oneX-radio-group">
              <div className="-oneX-radio-item">
                <input id='mobileNumber' type="radio" value='mobileNumber' className="-oneX-radio" name="group9" defaultChecked={defaultCheckedForMobilePhoneNumber()} onInput={checkForEmptyFields.bind(this)} onClick={enableSMS.bind(this)}/>
                <label htmlFor='mobileNumber'>mobile</label>
              </div>
              <div className="-oneX-radio-item">
                <input id='homeOtherNumber' type="radio" value='homeOtherNumber' className="-oneX-radio" name="group9" defaultChecked={defaultCheckedForHomeOtherPhoneNumber()} onInput={checkForEmptyFields.bind(this)} onClick={disableSMS.bind(this)}/>
                <label htmlFor='homeOtherNumber'>home/other</label>
              </div>
            </div>
          </fieldset>
        </div>
      </div>

      <div className="-oneX-row pb-4 pb-md-5">
        <div className="-oneX-col-12 -oneX-col-md-10 -oneX-offset-md-1 -oneX-col-lg-8 -oneX-offset-lg-2">
          <div className="-oneX">
            <input id="textMessageConsent" type="checkbox" className="-oneX-checkbox" onInput={checkForEmptyFields.bind(this)} onClick={clickConsentToSMS.bind(this)} disabled={defaultCheckedForHomeOtherPhoneNumber()}/>
            <label htmlFor="textMessageConsent"><span><strong style={{color: 'black'}}>(Optional) Text/SMS messages.</strong> I consent to receive automated important delivery and installation updates via text/SMS to the phone number I have provided from Whisker Labs. I own or am the primary user of this number, and will notify Whisker Labs if the number changes. I can reply with &quot;STOP&quot; at any time to be automatically unsubscribed from text/SMS messages. Message and data rates may apply.</span></label>
          </div>
        </div>
      </div>

      <div className="-oneX-row py-4 py-md-5">
          <div className="-oneX-col-12 -oneX-col-md-10 -oneX-offset-md-1 -oneX-col-lg-8 -oneX-offset-lg-2">
              <div style={{borderBottom: '1px solid lightgray'}}></div>
          </div>
      </div>

      <div className="-oneX-row py-4 py-md-5">
        <div className="-oneX-col-12 -oneX-col-md-10 -oneX-offset-md-1 -oneX-col-lg-8 -oneX-offset-lg-2">
          <p role="heading" aria-level="3" className="-oneX-heading--h3">Eligible Addresses</p>
        </div>

        <div className="-oneX-col-12 -oneX-col-md-10 -oneX-offset-md-1 -oneX-col-lg-8 -oneX-offset-lg-2">
          <fieldset className="-oneX -oneX-fieldset">
            <legend className="-oneX-legend"></legend>
          <div className="-oneX-widget--err">
            <div className="-oneX-radio-group -oneX--stacked">
              {
                data.eligibleResidences.map((eligibleAddress, index) =>
                  <EligibleAddress selectedAddressIndex={selectedAddressIndex} onSelectedAddress={setSelectedAddressIndex} address={eligibleAddress} key={index} index={index}/>
                )
              }
            </div>
          </div>
          </fieldset>
        </div>
      </div>

      <div className="-oneX-row py-4 py-md-5">
        <div className="-oneX-col-12 -oneX-col-md-10 -oneX-offset-md-1 -oneX-col-lg-8 -oneX-offset-lg-2">
          <p role="heading" aria-level="3" className="-oneX-heading--h3">Shipping Address</p>
        </div>

        <div className="-oneX-col-12 -oneX-col-md-10 -oneX-offset-md-1 -oneX-col-lg-8 -oneX-offset-lg-2">
          <input id="sameAsEligibleAddress" type="checkbox" className="-oneX-checkbox" defaultChecked={true} onClick={toggleShippingAddress.bind(this)} onInput={checkForEmptyFields.bind(this)}/>
          <label htmlFor="sameAsEligibleAddress">Same as Eligible Address</label>
        </div>

        <div id="shippingAddressContainer" className="-oneX-col-12 -oneX-col-md-10 -oneX-offset-md-1 -oneX-col-lg-8 -oneX-offset-lg-2 pt-4 " style={{visibility: 'hidden'}} onInput={checkForEmptyFields.bind(this)}>
          <div id="shipping-div" data-type="1x-exploding-field-address" data-lines="1" data-base_id="shipping">
          </div>
        </div>
      </div>

      <div className="-oneX-row pt-3 pt-md-5">
        <div className="-oneX-col-12 d-flex justify-content-center">
          <p role="heading" aria-level="3" className="text-center -oneX-heading--h3">Program Terms</p>
        </div>

        <div className="-oneX-col-12">
          <TermsAndConditions selectedAddressIndex={selectedAddressIndex} eligibleResidences={data.eligibleResidences}/>
        </div>
      </div>

      <div className="-oneX-row">
        <div className="-oneX-col-12 d-flex justify-content-center pt-4">
            <button id="submitButton" type="submit" className="-oneX-btn-primary -oneX-btn-fit-content" disabled={true}>Enroll</button>
        </div>
      </div>
    </form>
    <ChatBot/>
  </div>
  )
}
