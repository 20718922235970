
import logger from '../utils/logger.js';
import sleeper from '../utils/sleep-promise.js';
import { refreshOktaToken, getOktaToken, getSSOToken } from '../utils/session-management.js';


export async function getResidences(tries = 1) {
  let authToken;
  // Prioritize Okta, but use SSO as fallback
  if (await refreshOktaToken()) { // Refresh the token right before sending it downstream
    authToken = "Bearer " + getOktaToken();
  } else { // Default to SSO if Okta is "not good/available", at least until SSO is sunset
    authToken = getSSOToken();
  }

  try {
    const res = await fetch(
      `${process.env.REACT_APP_TING_API_URL}/pa/residences`,
      {
        headers: {
          "Content-Type": "application/json",
          "authorizationToken": authToken,
        },
        method: 'GET'
      }
    ).catch(error => {
      logger("getResidences fetch error", error)
      res.status(500).json({ response: "Something Went Wrong." })
      res.end()
    });

    if (res.status !== 200 && res.status !== 401 && res.status !== 403
        && tries < process.env.REACT_APP_MAX_GET_RESIDENCIES_TRIES
    ) {
      // Wait time to try again gets incrementally longer
      await sleeper(tries * process.env.REACT_APP_GET_RESIDENCES_RETRY_WAIT_INTERVAL);
      // Recursively call getResidences() up until REACT_APP_MAX_GET_RESIDENCIES_TRIES when there's an error
      logger(`getResidences 5xx error retry #${tries}`);
      return getResidences(tries + 1);
    }
    return res;
  } catch (error) {
    if (tries < process.env.REACT_APP_MAX_GET_RESIDENCIES_TRIES) {
      // Wait time to try again gets incrementally longer
      await sleeper(tries * process.env.REACT_APP_GET_RESIDENCES_RETRY_WAIT_INTERVAL);
      // Recursively call getResidences() up until REACT_APP_MAX_GET_RESIDENCIES_TRIES when there's an error
      logger(`getResidences error retry #${tries}`);
      return getResidences(tries + 1);
    }
    return {
      timedOut: true
    };
  }
}